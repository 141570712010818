import React from "react"
// import styled from "styled-components"
import { useScrollFreeze } from "../../hooks"
import NavLinks from "./NavLinks"

const MobileNavbar = isMenuOpen => {
  useScrollFreeze(isMenuOpen)

  return (
    <div className="fixed z-40">
      <nav
        className={`fixed top-0 left-0 h-full w-full flex justify-center items-center opacity-95 bg-theme-bg-tertiary`}
      >
        <NavLinks />
      </nav>
    </div>
  )
}

export default MobileNavbar

// const MobileNav = styled.nav`
//   z-index: 1;
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   background: ${props => props.theme.background.mobile};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 0.95;
// `
