import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IconRounded = ({ icon, bgColor }) => {
  return (
    <div
      className={`flex justify-center items-center w-12 h-12 rounded-full text-3xl shadow 
      ${!bgColor && `bg-black`}
      ${bgColor === `blue` && `bg-blue-pastel`}
      ${bgColor === `red` && `bg-red-pastel`}
      ${bgColor === `green` && `bg-green-pastel`}
      ${bgColor === `purple` && `bg-purple-pastel`}
      ${bgColor === `orange` && `bg-orange-pastel`}
      `}
      //cannot use this :
      //bgColor ? `bg-${bgColor}-pastel` : "bg-black"
      //see https://tailwindcss.com/docs/optimizing-for-production
    >
      <FontAwesomeIcon icon={icon} color="white" />
    </div>
  )
}

export default IconRounded

IconRounded.propTypes = {
  icon: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["blue", "red", "green", "purple", "orange"])
    .isRequired,
}
