import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Icon = ({ icon, size, color }) => {
  return (
    <div className="mb-20 border-solid border-2 border-purple-200 rounded-full w-28 h-28 flex justify-center items-center">
      <FontAwesomeIcon icon={icon} size={size} color={color} />
    </div>
  )
}

export default Icon
