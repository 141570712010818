import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import TextTransition, { presets } from "react-text-transition"
import { useMediaQuery } from "../../hooks"
import RoundedLinkButton from "../Buttons/RoundedLinkButton"
import Wave from "../../../static/wave.svg"

const StaticImageDisplayer = ({ alt, className }) => (
  <StaticImage
    className={className}
    src="../../../static/images/app-build_croped-min.png"
    alt={alt}
    placeholder="none"
    formats={["webp"]}
  />
)

const labels = [
  "HEADER_LABEL_WEB_APPS",
  "HEADER_LABEL_MOBILE_APPS",
  "HEADER_LABEL_WEBSITES",
  "HEADER_LABEL_ECOMMERCES",
  "HEADER_LABEL_API",
  "HEADER_LABEL_BACKEND",
]

const Home = () => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const isMobile = useMediaQuery("screen and (max-width: 480px)")

  // For TextTransition
  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 1500)
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <>
      <section
        id={`${t("NAVBAR_ITEM_HOME")}`}
        // className="bg-aliceblue pt-4 lg:pt-16 pb-8"
        className="bg-aliceblue"
      >
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="flex-1 w-full whitespace-pre-line mt-10 lg:mt-40">
            {/* <div className="text-theme-text-primary font-headline capitalize-first h-40 text-3xl md:text-4xl mb-10 lg:mb-20"> */}
            <div className="h-2/3 mb-10 lg:mb-0 text-theme-text-primary font-headline capitalize-first text-3xl md:text-4xl">
              {t("HEADER_LABEL_WEBUILD")}&nbsp;
              {isMobile && <br />}
              <TextTransition
                text={t(labels[index % labels.length])}
                springConfig={presets.wobbly}
                noOverflow={true}
                inline={true}
                className="text-theme-text-secondary z-0"
              />
              <br />
              {`${t("HEADER_LABEL_FOROUR")} `}
              <s>{`${t("HEADER_LABEL_CUSTOMERS")}`}</s>
              {` ${t("HEADER_LABEL_PARTNERS")}`}
            </div>
            <div className="h-1/3 flex flex-1 justify-start items-end pb-10 md:pb-5 lg:pb-0">
              <RoundedLinkButton
                label={t("BUTTON_LABEL_CONTACT_US")}
                url={`#${t("NAVBAR_ITEM_CONTACT")}`}
                size="medium"
                variant="primary"
              />
            </div>
          </div>
          {/* <div className="flex flex-1 justify-center pt-10 lg:pt-0 lg:justify-start 2xl:justify-end"> */}
          <div className="flex flex-1 justify-center pt-10 lg:justify-start xl:justify-end">
            <StaticImageDisplayer
              className="w-full h-full md:max-w-lg z-0"
              alt={t("SECTION_HOME_IMAGE_ALT")}
            />
          </div>
        </div>
      </section>
      <div className="">
        <img className="" src={Wave} />
      </div>
    </>
  )
}

export default Home
