import React from "react"
import PropTypes from "prop-types"

const ImageCard = ({ img, alt, shadowColor }) => {
  return (
    <div
      className={`flex bg-white w-28 h-28 m-2.5 rounded-2xl 
      ${!shadowColor && `shadow-black`}
      ${shadowColor === `blue` && `shadow-blue`}
      ${shadowColor === `red` && `shadow-red`}
      ${shadowColor === `green` && `shadow-green`}
      ${shadowColor === `purple` && `shadow-purple`}
      ${shadowColor === `orange` && `shadow-orange`}
      `}
      //cannot use this :
      //shadow-${shadowColor ? shadowColor : `black`}
      //see https://tailwindcss.com/docs/optimizing-for-production
    >
      <img className="m-auto" src={img} alt={alt} />
    </div>
  )
}

export default ImageCard

ImageCard.propTypes = {
  img: PropTypes.string.isRequired,
  shadowColor: PropTypes.oneOf(["blue", "red", "green", "purple", "orange"])
    .isRequired,
}
