import React from "react"
import { graphql } from "gatsby"
import MainNavbar from "../components/Navbar/MainNavbar"
// import About from "../components/sections/About"
import Services from "../components/sections/Services"
import Techs from "../components/sections/Techs"
import Contact from "../components/sections/Contact"
import Home from "../components/sections/Home"
import Layout from "../components/Layout/Layout"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"

const App = () => {
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <Layout
      title={t("SEO_PAGE_INDEX_TAB_TITLE")}
      description={t("SEO_PAGE_INDEX_DESCRIPTION")}
      lang={(language === "fr" && "fr-FR") || (language === "en" && "en-US")}
    >
      <MainNavbar />
      <Home />
      {/* <About /> */}
      <Services />
      <Techs />
      <Contact />
    </Layout>
  )
}

export default App

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
