import React from "react"
import PropTypes from "prop-types"
import ImageCard from "./ImageCard"

const ImageCardDisplayer = ({ title, imageArray, color }) => (
  <div className="mb-10 last:mb-0 lg:last3mb0">
    <h2
      className={`font-headline capitalize 
      ${!color && `text-black`}
      ${color === `blue` && `text-blue-pastel`}
      ${color === `red` && `text-red-pastel`}
      ${color === `green` && `text-green-pastel`}
      ${color === `purple` && `text-purple-pastel`}
      ${color === `orange` && `text-orange-pastel`}
   `}
      //cannot use this :
      // text-${ color ? `${color}-pastel` : `black` }
      //see https://tailwindcss.com/docs/optimizing-for-production
    >
      {title}
    </h2>
    {imageArray && (
      <div className="flex flex-wrap mt-5">
        {imageArray.map((item, index) => (
          <ImageCard
            key={index}
            img={item.img}
            alt={item.alt}
            shadowColor={color}
          ></ImageCard>
        ))}
      </div>
    )}
  </div>
)

export default ImageCardDisplayer

ImageCardDisplayer.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["blue", "red", "green", "purple", "orange"]),
  imageArray: PropTypes.array.isRequired,
}
