import React from "react"
// import styled from "styled-components"
import Hamburger from "hamburger-react"

const MainHamburger = ({ isOpen, setOpen, isScrolled }) => {
  return (
    <div
      className={`flex justify-center items-center fixed w-16 h-16 bottom-6 right-8 rounded-full ${
        isScrolled && !isOpen
          ? "opacity-90 text-theme-text-tertiary bg-theme-bg-secondary"
          : "bg-none"
      } transition ease-in-out duration-500`}
    >
      <Hamburger direction="left" toggled={isOpen} toggle={setOpen} />
    </div>
  )
}

export default MainHamburger

// const Container = styled.div`
//   height: 70px;
//   width: 70px;
//   position: fixed !important;
//   right: 30px;
//   bottom: 20px;
//   background: ${props => (props.isScrolled & !props.isOpen ? "" : "black")};
//   transition: background-color 0.5s ease;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `
