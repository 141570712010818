import React from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

const ToggleLngButton = () => {
  const { languages, originalPath, language } = useI18next()

  return (
    <ul className="flex items-center">
      {languages.map((lng, index) => (
        <React.Fragment key={lng}>
          <li
            key={lng}
            className={`mx-1.5 uppercase cursor-pointer font-bolded ${
              lng === language && `text-custom-pink`
            }`}
          >
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
          {index === languages.length - 2 && "/"}
        </React.Fragment>
      ))}
    </ul>
  )
}

export default ToggleLngButton
