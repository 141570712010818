import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  faLightbulb,
  faChartLine,
  faHammer,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons"
import MultiContentCardDisplayer from "../Cards/MultiContentCard/MultiContentCardDisplayer"

//TODO : contenu title/text fr/en
const cards = [
  {
    icon: faLightbulb,
    color: "purple",
    title: "SECTION_SERVICES_FIRST_CARD_TITLE",
    text: "SECTION_SERVICES_FIRST_CARD_TEXT",
  },
  {
    icon: faLayerGroup,
    color: "blue",
    title: "SECTION_SERVICES_SECOND_CARD_TITLE",
    text: "SECTION_SERVICES_SECOND_CARD_TEXT",
  },
  {
    icon: faHammer,
    color: "green",
    title: "SECTION_SERVICES_THIRD_CARD_TITLE",
    text: "SECTION_SERVICES_THIRD_CARD_TEXT",
  },
  {
    icon: faChartLine,
    color: "orange",
    title: "SECTION_SERVICES_FOURTH_CARD_TITLE",
    text: "SECTION_SERVICES_FOURTH_CARD_TEXT",
  },
]

const Services = () => {
  const { t } = useTranslation()
  return (
    <section id={`${t("NAVBAR_ITEM_SERVICES")}`} className="mt-10 md:mt-14">
      <div className="flex flex-col">
        <div className="flex flex-1">
          <div className="w-3/4">
            <h1 className="text-gray-400 uppercase mb-4 tracking-widest">
              {t("SECTION_SERVICES_INTEEK")}
            </h1>
            <h1 className="text-theme-text-primary capitalize-first mb-4">
              {t("SECTION_SERVICES_TITLE")}
            </h1>
            <p className="mb-10 capitalize-first">
              {t("SECTION_SERVICES_PUNCHLINE")}
            </p>
          </div>
        </div>
        <div className="flex flex-1">
          <MultiContentCardDisplayer dataArray={cards} />
        </div>
      </div>
    </section>
  )
}

export default Services
