import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { sendEmail } from "../../utils/helpers"
import RoundedButton from "../Buttons/RoundedButton"
import Textfield from "../Textfields/Textfield"
import { contactFormValidationSchema } from "./ContactFormValidationSchema"
import AlertMsg from "./AlertMsg"

const fields = [
  {
    name: "name",
    label: "SECTION_CONTACT_LABEL_INPUT_NAME",
  },
  {
    name: "firstname",
    label: "SECTION_CONTACT_LABEL_INPUT_FIRSTNAME",
  },
  {
    name: "company",
    label: "SECTION_CONTACT_LABEL_INPUT_COMPANY",
  },
  {
    name: "phone",
    label: "SECTION_CONTACT_LABEL_INPUT_PHONE",
  },
  {
    name: "email",
    label: "SECTION_CONTACT_LABEL_INPUT_EMAIL",
  },
  {
    name: "subject",
    label: "SECTION_CONTACT_LABEL_INPUT_SUBJECT",
  },
  {
    name: "msg",
    label: "SECTION_CONTACT_LABEL_INPUT_MSG",

    isTextarea: true,
  },
]

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(contactFormValidationSchema),
  })
  const { t } = useTranslation()
  const [successMsg, setSuccessMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const onSuccess = () => {
    setSuccessMsg(true)
  }

  const onError = () => {
    setErrorMsg(true)
  }

  const onSubmit = data => sendEmail(data, onSuccess, onError)

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-64">
      {fields.map(({ name, label, isTextarea }, index) => (
        <Textfield
          key={index}
          name={name}
          label={t(label)}
          error={errors[name]}
          isTextarea={isTextarea}
          {...register(name)}
        />
      ))}
      {successMsg && (
        <AlertMsg success msg={t("CONTACT_FORM_ALERT_MSG_SUCCESS")} />
      )}
      {errorMsg && <AlertMsg error msg={t("CONTACT_FORM_ALERT_MSG_ERROR")} />}
      <div className="mt-10">
        <RoundedButton
          variant="primary"
          size="large"
          label={
            isSubmitting
              ? t("CONTACT_FORM_BTN_IS_SENDING")
              : t("CONTACT_FORM_BTN_SEND")
          }
          disabled={!isValid || isSubmitting}
        />
      </div>
    </form>
  )
}

export default ContactForm
