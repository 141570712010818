import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Icon from "../Icons/Icon"
import {
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faAt,
} from "@fortawesome/free-solid-svg-icons"
import ContactForm from "../Forms/ContactForm"

const Contact = () => {
  const { t } = useTranslation()

  return (
    <section
      id={`${t("NAVBAR_ITEM_CONTACT")}`}
      className="mt-24 md:mt-28 mb-20"
    >
      <h1 className="mb-10 text-theme-text-primary capitalize">
        {t("SECTION_CONTACT_TITLE")}
      </h1>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-1 flex-col mb-10 border-solid border-b border-purple-200 md:border-b-0 md:border-solid md:border-r md:border-purple-200">
          <div className="flex flex-1 flex-col text-center items-center break-all px-2 pb-10 border-solid border-b border-purple-200 sm:border-0">
            <Icon icon={faAt} size="3x" color="#ff5ccd" />
            <span className="text-theme-text-primary">contact@inteek.io</span>
          </div>
          <div className="flex flex-1 flex-col text-center items-center break-all px-2 py-10">
            <Icon icon={faMapMarkerAlt} size="3x" color="#ff5ccd" />
            <span className="capitalize text-theme-text-primary">
              {t("SECTION_CONTACT_LABEL_LOCATION")}
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center text-center">
          <Icon icon={faEnvelopeOpenText} size="3x" color="#ff5ccd" />
          <ContactForm />
        </div>
      </div>
    </section>
  )
}

export default Contact
