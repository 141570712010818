import React from "react"
import PropTypes from "prop-types"
import IconRounded from "../../Icons/IconRounded"

const MultiContentCard = ({ icon, color, title, text }) => {
  return (
    <div
      className={`flex flex-col w-64 h-54 mb-10 md:last2mb0 max-sm:last:mb-0 xl:mb-0`}
    >
      <IconRounded icon={icon} bgColor={color}></IconRounded>
      <h3 className="mt-3 text-xl font-bolded break-words tracking-wide line-clamp-2 capitalize">
        {title}
      </h3>
      <p className="mt-3 text-md break-words line-clamp-2">{text}</p>
    </div>
  )
}

export default MultiContentCard

MultiContentCard.propTypes = {
  icon: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["blue", "red", "green", "purple", "orange"])
    .isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
