import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"

const AlertMsg = ({ msg, success, error }) => {
  return (
    <div
      className={`flex text-left font-black rounded-b px-4 py-3 shadow-md mb-10 ${
        success && `bg-green-200 text-green-800`
      } ${error && `bg-red-200 text-red-700`} `}
      role="alert"
    >
      <div className="flex">
        <div className="mr-1">
          <FontAwesomeIcon
            icon={(success && faCheckCircle) || (error && faExclamationCircle)}
            color={(success && "green") || (error && "red")}
            size="1x"
          />
        </div>
        <span className="text-sm">{msg}</span>
      </div>
    </div>
  )
}

export default AlertMsg
