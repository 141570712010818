import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

const Textfield = React.forwardRef(
  ({ name, label, error, isTextarea, ...props }, ref) => {
    const { t } = useTranslation()
    return (
      <div className="text-left mb-6 w-full">
        <label
          htmlFor={name}
          className="block text-sm uppercase font-semibold text-black "
        >
          {label}
        </label>
        {isTextarea ? (
          <textarea
            ref={ref}
            rows="7"
            name={name}
            type="text"
            className={`w-full border border-purple-200 py-2 px-3 text-grey-darkest rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent ${
              error && `border-solid border-2 border-red-500`
            }`}
            {...props}
          />
        ) : (
          <input
            ref={ref}
            id={name}
            name={name}
            type="text"
            className={`w-full border	border-purple-200 py-2 px-3 text-grey-darkest rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent ${
              error && `border-solid border-2 border-red-500`
            }`}
            {...props}
          />
        )}
        {error?.message && (
          <div className="flex lg:items-center font-black tracking-wide text-red-500 text-xs mt-1 ml-1">
            <FontAwesomeIcon icon={faExclamationCircle} size="1x" color="red" />
            <span className="ml-1 lg:whitespace-nowrap">
              {t(error.message)}
            </span>
          </div>
        )}
      </div>
    )
  }
)

export default Textfield
