import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ImageCardDisplayer from "../Cards/ImageCard/ImageCardDisplayer"
import reactLogo from "../../../static/images/logo_react-80x80.webp"
import ansibleLogo from "../../../static/images/logo_ansible-65x80.webp"
import reactNativeLogo from "../../../static/images/logo_react_native-80x80.webp"
import firebaseLogo from "../../../static/images/logo_firebase-80x54.webp"
import dockerLogo from "../../../static/images/logo_docker-80x69.webp"
import awsLogo from "../../../static/images/logo_aws-80x48.webp"
import googleAnalyticsLogo from "../../../static/images/logo_google_analytics_80x59.webp"
import nodeLogo from "../../../static/images/logo_nodejs-80x80.webp"
import mongoDbLogo from "../../../static/images/logo_mongodb-80x80.webp"
import sqlLogo from "../../../static/images/logo_sql-80x80.webp"
import gatsbyLogo from "../../../static/images/logo_gatsby-80x80.webp"

const firstTechsGroup = [
  {
    title: "SECTION_TECHS_LABEL_WEB",
    color: "blue",
    imageArray: [
      { img: reactLogo, alt: "react logo" },
      { img: gatsbyLogo, alt: "gatsby logo" },
    ],
  },
  {
    title: "SECTION_TECHS_LABEL_BACKEND",
    color: "red",
    imageArray: [
      { img: nodeLogo, alt: "node logo" },
      { img: mongoDbLogo, alt: "mongodb logo" },
      { img: sqlLogo, alt: "sql logo" },
    ],
  },
]

const secondTechsGroup = [
  {
    title: "SECTION_TECHS_LABEL_MOBILE",
    color: "green",
    imageArray: [{ img: reactNativeLogo, alt: "react native logo" }],
  },
  {
    title: "SECTION_TECHS_LABEL_DEVOPS",
    color: "purple",
    imageArray: [
      { img: dockerLogo, alt: "docker logo" },
      { img: awsLogo, alt: "aws logo" },
      { img: ansibleLogo, alt: "ansible logo" },
    ],
  },
  {
    title: "SECTION_TECHS_LABEL_ANALYTICS",
    color: "orange",
    imageArray: [
      { img: firebaseLogo, alt: "firebase logo" },
      { img: googleAnalyticsLogo, alt: "google analytics logo" },
    ],
  },
]

const Techs = () => {
  const { t } = useTranslation()
  return (
    <section id={`${t("NAVBAR_ITEM_TECHS")}`} className="mt-24 md:mt-28">
      <h1 className="mb-10 text-theme-text-primary capitalize-first">
        {t("SECTION_TECHS_TITLE")}
      </h1>
      <div className="flex flex-wrap justify-between mb-5">
        {firstTechsGroup.map(item => (
          <ImageCardDisplayer
            key={t(item.title)}
            title={t(item.title)}
            color={item.color}
            imageArray={item.imageArray}
          />
        ))}
      </div>
      <div className="flex flex-wrap justify-between">
        {secondTechsGroup.map(item => (
          <ImageCardDisplayer
            key={t(item.title)}
            title={t(item.title)}
            color={item.color}
            imageArray={item.imageArray}
          />
        ))}
      </div>
    </section>
  )
}

export default Techs
