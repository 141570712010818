import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const RoundedButton = ({
  label = "click me !",
  onPress,
  disabled,
  size = "medium",
  variant = "primary",
}) => {
  return (
    <button
      name={label}
      onClick={onPress}
      disabled={disabled}
      size={size}
      variant={variant}
      className={classnames(
        `flex justify-center items-center rounded-3xl uppercase cursor-pointer tracking-wider	font-headline py-3 px-4 border-solid border-2 transition-all ease-linear`,
        {
          "w-full": size === "large",
          "w-48": size === "medium",
          "pointer-events-none opacity-30": disabled,
          "border-theme-btn-primary bg-theme-btn-primary text-theme-btn-text-primary lg:hover:bg-theme-btn-secondary lg:hover:text-theme-btn-text-secondary":
            variant === "primary",
          "border-theme-btn-primary bg-theme-btn-secondary text-theme-btn-text-secondary lg:hover:bg-theme-btn-primary lg:hover:text-theme-btn-text-primary":
            variant === "secondary",
        }
      )}
    >
      {label}
    </button>
  )
}

export default RoundedButton

RoundedButton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  disabled: PropTypes.bool,
}
