import * as yup from "yup"

const phoneRegex = /[0-9\-+()x]+$/g

const contactFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "CONTACT_FORM_ERROR_NAME_MIN_LENGTH")
    .max(60, "CONTACT_FORM_ERROR_NAME_MAX_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
  firstname: yup
    .string()
    .min(3, "CONTACT_FORM_ERROR_NAME_MIN_LENGTH")
    .max(60, "CONTACT_FORM_ERROR_NAME_MAX_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
  company: yup
    .string()
    // .min(3, "CONTACT_FORM_ERROR_NAME_MIN_LENGTH")
    .max(80, "CONTACT_FORM_ERROR_NAME_MAX_LENGTH"),
  // .required("CONTACT_FORM_ERROR_REQUIRED"),
  phone: yup
    .string()
    .matches(phoneRegex, "CONTACT_FORM_ERROR_PHONE_CHARACTERS")
    .min(7, "CONTACT_FORM_ERROR_PHONE_MIN_LENGTH")
    .max(20, "CONTACT_FORM_ERROR_PHONE_MAX_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
  email: yup
    .string()
    .email("CONTACT_FORM_ERROR_EMAIL")
    .min(3, "CONTACT_FORM_ERROR_EMAIL_MIN_LENGTH")
    .max(80, "CONTACT_FORM_ERROR_EMAIL_MAX_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
  subject: yup
    .string()
    .min(3, "CONTACT_FORM_ERROR_SUBJECT_MIN_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
  msg: yup
    .string()
    .min(3, "CONTACT_FORM_ERROR_MSG_MIN_LENGTH")
    .required("CONTACT_FORM_ERROR_REQUIRED"),
})

export { contactFormValidationSchema }
