import React from "react"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby"
import { useMenuContext } from "../../state"
import ToggleLngButton from "../Buttons/ToggleLngButton"
import RoundedLinkButton from "../Buttons/RoundedLinkButton"

const links = ["NAVBAR_ITEM_HOME", "NAVBAR_ITEM_SERVICES", "NAVBAR_ITEM_TECHS"]

// see https://github.com/tailwindlabs/tailwindcss/discussions/1745 for first-letter capitalize
const NavLinks = () => {
  const { closeMenu } = useMenuContext()
  const { t } = useTranslation()

  return (
    <ul className="flex flex-col font-bolded list-none items-center lg:flex-row lg:font-regular">
      {links.map(link => (
        <li
          className="mb-7 capitalize-first text-2xl lg:text-base lg:mr-7 lg:mb-0"
          key={t(link)}
        >
          <NavLink to={`#${t(link)}`} onClick={closeMenu}>
            {t(link)}
          </NavLink>
        </li>
      ))}
      <div className="mb-7 text-2xl lg:text-base lg:mb-0">
        <RoundedLinkButton
          label={t("BUTTON_LABEL_CONTACT_US")}
          url={`#${t("NAVBAR_ITEM_CONTACT")}`}
          size="small"
          variant="secondary transparent"
          onPress={closeMenu}
        />
      </div>
      <div className="text-2xl lg:text-base lg:ml-7">
        <ToggleLngButton />
      </div>
    </ul>
  )
}

export default NavLinks

// Need activate just-in-time mode if want to use before
// see https://dev.to/j471n/how-to-use-before-and-after-in-tailwind-css-1he
// see https://tailwindcss.com/docs/just-in-time-mode
// that's why we use styled component here

export const NavLink = styled(Link)`
  position: relative;
  color: white;
  text-decoration: none;
  // color: ${props => props.theme.text.primary};
  color: black;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 2px;
    width: 0;
    // background: ${props => props.theme.text.primary};
    background: black;
    transition: width 150ms linear;
  }
  &:hover::before {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    &:hover::before {
      display: none;
    }
  }
`
