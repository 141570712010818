import emailjs from "emailjs-com"
import {
  EMAILJS_USER_ID,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
} from "gatsby-env-variables"

const isDev = process.env.NODE_ENV !== "production"

export const sendEmail = async (data, onSuccess, onError) => {
  try {
    const request = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      data,
      EMAILJS_USER_ID
    )

    if (request.status === 200) return onSuccess()
    isDev && console.log(request.status, request.text)
    return onError()
  } catch (error) {
    isDev && console.log("error : ", error)
    return onError()
  }
}
