import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "gatsby-plugin-react-i18next"
import MultiContentCard from "./MultiContentCard"

const MultiContentCardDisplayer = ({ dataArray }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full flex flex-wrap justify-between">
      {dataArray &&
        dataArray.map(({ icon, color, title, text }, index) => (
          <MultiContentCard
            key={index}
            icon={icon}
            color={color}
            title={t(title)}
            text={t(text)}
          />
        ))}
    </div>
  )
}

export default MultiContentCardDisplayer

MultiContentCardDisplayer.propTypes = {
  dataArray: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
}
