import React from "react"
import Navbar from "./Navbar"
import MobileNavbar from "./MobileNavbar"
import { useScroll } from "../../hooks"
import { useMenuContext } from "../../state"
import NavLinks from "./NavLinks"
import MainHamburger from "./MainHamburger"

const MainNavbar = () => {
  const { isScrolled } = useScroll()
  const { isMenuOpen, toggleMenu, isMobile } = useMenuContext()

  return (
    <>
      <Navbar
        isScrolled={isScrolled}
        menu={
          isMobile !== undefined &&
          (isMobile ? (
            <MainHamburger
              direction="left"
              isOpen={isMenuOpen}
              setOpen={toggleMenu}
              isScrolled={isScrolled}
            />
          ) : (
            <NavLinks />
          ))
        }
      />
      {isMobile && isMenuOpen && <MobileNavbar isMenuOpen />}
    </>
  )
}

export default MainNavbar
